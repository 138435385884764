import React, { FC } from 'react';
import { Box } from '@mui/material';
import {
  AboutComponent,
  BannerComponent,
  FeelCardsComponent,
  HeaderComponent,
  HelpCardsComponent,
  HomeTestimonialsComponent
} from '../../components';

interface IProps {
  handleOpenBookModal: () => void;
}

export const Home: FC<IProps> = ({ handleOpenBookModal }) => {
  
  return (
    <Box sx={{ width: '100%' }}>
      <HeaderComponent handleOpenBookModal={handleOpenBookModal} />
      <FeelCardsComponent handleOpenBookModal={handleOpenBookModal} />
      <AboutComponent />
      <HelpCardsComponent />
      <BannerComponent handleOpenBookModal={handleOpenBookModal} />
      <HomeTestimonialsComponent />
    </Box>
  );
};