import React, { FC } from 'react';
import { Box, Button, Container, Grid, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const AboutComponent: FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Container maxWidth="xl" sx={{
      padding: { xs: '0 40px 40px 40px', lg: '0 64px 64px 64px' }
    }}>
      <Grid container spacing={0} columns={2}>
        <Grid item xs={2} lg={1}>
          <img src='assets/images/about.jpg' alt='about me' width='100%' height='auto' />
        </Grid>
        <Grid item xs={2} lg={1}>
          <Box sx={{
            padding: '36px',
            position: 'relative',
            textAlign: { xs: 'center', lg: 'left' }
          }}
          >
            <Typography mb='8px' variant='h4'>{t('home.about.title')}</Typography>
            <Typography variant='body1' color="primary" sx={{ textTransform: 'uppercase' }}>{t('home.about.subtitle')}</Typography>
            <Box sx={{
              margin: { xs: '24px auto', lg: '24px 0' },
              width: '80px',
              height: '6px',
              backgroundColor: theme.palette.secondary.main
            }}></Box>
            <Typography mb='12px' variant='subtitle2' color="text.primary">{t('home.about.description')}</Typography>
            <Typography mb='36px' variant='subtitle2' color="text.primary">{t('home.about.description2')}</Typography>
            <Button variant='outlined' href="/about">{t('home.about.button')}</Button>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};