import React, { FC, useEffect, useMemo, useState } from 'react';
import { Box} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Navigate } from "react-router-dom";

import { AuthService } from "../../services";
import { IUser } from '../../models';

interface IProfileState {
  redirect: string | null,
  userReady: boolean,
  currentUser: IUser & { accessToken: string }
}

export const Profile: FC = () => {
  const { t } = useTranslation();
  const authService = useMemo(() => new AuthService(), []);
  const [state, setState] = useState<IProfileState>({
    redirect: null,
    userReady: false,
    currentUser: { accessToken: "" }
  });

  useEffect(() => {
    const currentUser = authService.getCurrentUser();

    if (!currentUser) {
      setState({ ...state, redirect: "/" });
    }
    if (JSON.stringify(state.currentUser) !== JSON.stringify(currentUser)) {
      setState({ ...state, currentUser: currentUser, userReady: true })
    }
  }, [authService, state]);

  return (
    <>
      {state.redirect ? (
        <Navigate to={state.redirect} />
      ) : (
        <Box sx={{
          width: '100%',
          padding: '24px 0 48px 0',
          backgroundColor: 'rgba(247,247,247,0.5)'
        }}>
          <div className="container">
            {(state.userReady && state.currentUser) ?
              <div>
                <header className="jumbotron">
                  <h3>
                    <strong>{state.currentUser.username}</strong> Profile
                  </h3>
                </header>
                <p>
                  <strong>Token:</strong>{" "}
                  {state.currentUser.accessToken.substring(0, 20)} ...{" "}
                  {state.currentUser.accessToken.substr(state.currentUser.accessToken.length - 20)}
                </p>
                <p>
                  <strong>Id:</strong>{" "}
                  {state.currentUser.id}
                </p>
                <p>
                  <strong>Email:</strong>{" "}
                  {state.currentUser.email}
                </p>
                <strong>Authorities:</strong>
                <ul>
                  {state.currentUser.roles &&
                    state.currentUser.roles.map((role, index) => <li key={index}>{role}</li>)}
                </ul>
              </div> : null}
          </div>
        </Box>
      )}
    </>
  );
};