import React, { FC, useEffect, useMemo, useState } from 'react';
import { Alert, Box, Button, Card, CardContent, CardHeader, CircularProgress, TextField, Typography} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFormik } from "formik";
import * as Yup from "yup";

import { AuthService } from "../../services";
import { Navigate } from 'react-router-dom';

interface ILoginState {
  redirect: string | null;
  username: string;
  password: string;
  loading: boolean;
  message: string;
}

export const Login: FC = () => {
  const { t } = useTranslation();
  const authService = useMemo(() => new AuthService(), []);
  const [state, setState] = useState<ILoginState>({
    redirect: null,
    username: "",
    password: "",
    loading: false,
    message: ""
  });

  const validationSchema = () => {
    return Yup.object().shape({
      username: Yup
        .string()
        .required('This field is required!'),
      password: Yup
        .string()
        .min(8, 'Password should be of minimum 8 characters length')
        .required('This field is required!'),
    });
  }


  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleLogin(values)
    },
  });

  useEffect(() => {
    const currentUser = authService.getCurrentUser();

    if (currentUser) {
      setState({ ...state, redirect: "/profile" });
    };
  }, [authService, state]);

  const handleLogin = (formValue: { username: string; password: string }) => {
    const { username, password } = formValue;

    setState({
      ...state,
      message: "",
      loading: true
    });

    authService.login(username, password).then(() => {
        setState({
          ...state,
          redirect: "/profile"
        });
      },
      error => {
        setState({
          ...state,
          message: error.response.data.message || '',
          loading: false
        });
      }
    );
  }

  return (
    <>
      {state.redirect ? (
        <Navigate to={state.redirect} />
      ) : (
        <Box sx={{
          width: '100%',
          padding: '48px 0',
          backgroundColor: 'rgba(247,247,247,0.5)'
        }}>
          <Box sx={{
            width: '100%',
            maxWidth: '600px',
            margin: '0 auto'
          }}>
            <Card sx={{
              padding: '12px'
            }}>
              <CardHeader
                sx={{ paddingBottom: 0 }}
                title={
                  <Typography mb='12px' variant='h4' color="primary">Log in</Typography>
                }
              />
              <CardContent>
                {state.message && <Alert sx={{ marginBottom: '24px' }} severity="error">{state.message}</Alert>}
                <form onSubmit={formik.handleSubmit}>
                  <TextField
                    sx={{ marginBottom: '24px' }}
                    fullWidth
                    id="username"
                    name="username"
                    label="Username"
                    value={formik.values.username}
                    onChange={formik.handleChange}
                    error={formik.touched.username && Boolean(formik.errors.username)}
                    helperText={formik.touched.username && formik.errors.username}
                  />
                  <TextField
                    sx={{ marginBottom: '24px' }}
                    fullWidth
                    id="password"
                    name="password"
                    label="Password"
                    type="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={formik.touched.password && Boolean(formik.errors.password)}
                    helperText={formik.touched.password && formik.errors.password}
                  />
                  <Button color="primary" variant="contained" type="submit" disabled={state.loading}>
                    Submit {state.loading && <CircularProgress sx={{ color: 'white', marginLeft: '3px' }} size='12px' />}
                  </Button>
                </form>
              </CardContent>
            </Card>
          </Box>
        </Box>
      )}
    </>
  );
};