import React, { FC } from 'react';
import { Box, Button, Container, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ICard } from '../../models';

interface IProps {
  handleOpenBookModal: () => void;
}

export const FeelCardsComponent: FC<IProps> = ({ handleOpenBookModal }) => {
  const { t } = useTranslation();

  const CARDS: ICard[] = [
    {
      title: t('home.feelCards.cards.powerless.title'),
      description: t('home.feelCards.cards.powerless.description'),
      icon: 'assets/images/nemocno.png'
    },
    {
      title: t('home.feelCards.cards.stuck.title'),
      description: t('home.feelCards.cards.stuck.description'),
      icon: 'assets/images/zaglavljeno.png'
    },
    {
      title: t('home.feelCards.cards.insecure.title'),
      description: t('home.feelCards.cards.insecure.description'),
      icon: 'assets/images/nesigurno.png'
    },
    {
      title: t('home.feelCards.cards.afraid.title'),
      description: t('home.feelCards.cards.afraid.description'),
      icon: 'assets/images/uplaseno.png'
    }
  ]

  return (
    <Container maxWidth="xl" sx={{
      padding: { xs: '40px', lg: '64px' },
    }}>
      <Typography variant='h4' sx={{ textAlign: 'center', padding: { xs: '0 0 32px 0', lg: '24px 0 40px 0' } }}>
        { t('home.feelCards.title') }
      </Typography>
      <Grid container columns={12}>
        {CARDS.map((card: ICard) => (
          <Grid item xs={12} lg={3} key={card.title} sx={{
            padding: '0 32px',
            marginBottom: { xs: '32px', lg: '0' },
            ':not(:first-of-type)': {
              borderLeft: { xs: 'none', lg: '2px solid rgba(0, 0, 0, 0.1)' }
            }
          }}>
            <Box sx={{
              textAlign: { xs: 'center', lg: 'left' }
              }}>
              <img src={card.icon} alt='about me' width='80px' height='80px' />
              <Typography variant="h5" color="primary" sx={{ margin: '24px 0 16px 0' }}>
                { card.title }
              </Typography>
              <Typography variant="body1">
                { card.description }
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
      <Button onClick={handleOpenBookModal} variant='contained' sx={{ display: 'block', margin: { xs: '24px auto', lg: '32px auto' } }}>{t('home.feelCards.button')}</Button>
    </Container>
  );
};