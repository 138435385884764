import React, { FC } from 'react';
import { Box, Button, Container, Grid, Icon, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface IProps {
  handleOpenBookModal: () => void;
}

export const BannerComponent: FC<IProps> = ({ handleOpenBookModal }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Box sx={{
      width: '100%',
      backgroundColor: theme.palette.primary.main
    }}>
      <Container maxWidth="xl" sx={{
        padding: { xs: '40px', lg: '64px' },
      }}>
        <Grid container spacing={0} columns={6}>
          <Grid item xs={6} lg={2}>
            <Box sx={{ paddingRight: { lg: '40px' }, paddingBottom: { xs: '32px', lg: '0' }, textAlign: { xs: 'center', lg: 'left' } }}>
              <Typography color="white" mb='14px' variant='h3'>{t('home.banner.left.title')}</Typography>
              <Typography color="white" variant='body1'>{t('home.banner.left.description')}</Typography>
            </Box>
          </Grid>
          <Grid item xs={6} lg={4}>
            <Box sx={{ paddingLeft: { lg: '36px' }, borderLeft: { lg: '2px solid #ffffff' }, borderTop: { xs: '2px solid #ffffff', lg: 'none' }, paddingTop: { xs: '32px', lg: '0' }  }}
            >
              <Typography color="white" mb='36px' variant='h5' sx={{ textAlign: { xs: 'center', lg: 'left' } }}>{t('home.banner.right.title')}</Typography>
              <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: { xs: 'flex-start', lg: 'center' }, marginBottom: '12px' }}>
                <Icon sx={{ fontSize: 16, color: '#ffffff', marginRight: '5px' }}>check</Icon>
                <Typography color="white" variant='body1'>{t('home.banner.right.description')}</Typography>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: { xs: 'flex-start', lg: 'center' }, marginBottom: '12px' }}>
                <Icon sx={{ fontSize: 16, color: '#ffffff', marginRight: '5px' }}>check</Icon>
                <Typography color="white" variant='body1'>{t('home.banner.right.description2')}</Typography>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: { xs: 'flex-start', lg: 'center' }, marginBottom: '36px' }}>
                <Icon sx={{ fontSize: 16, color: '#ffffff', marginRight: '5px' }}>check</Icon>
                <Typography color="white" variant='body1'>{t('home.banner.right.description3')}</Typography>
              </Box>
              <Box sx={{ textAlign: { xs: 'center', lg: 'left' } }}>
                <Button onClick={handleOpenBookModal} color="info" variant="contained">{t('home.banner.right.button')}</Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};