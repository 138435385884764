import React, { FC } from 'react';
import { Box, Button, Container, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TestimonialCardsComponent } from '../TestimonialCards/TestimonialCards';

export const HomeTestimonialsComponent: FC = () => {
  const { t } = useTranslation();

  return (
    <Box sx={{
      width: '100%'
    }}>
      <Container maxWidth="xl" sx={{
        padding: { xs: '40px', lg: '64px' }
      }}>
        <Typography variant="h4" sx={{ textAlign: 'center', marginBottom: { xs: '40px', lg: '64px' } }}>{t('home.homeTestimonials.title')}</Typography>
        <TestimonialCardsComponent />
        <Box sx={{
          marginTop: '40px',
          textAlign: 'center'
        }}>
          <Button variant='outlined' href="/testimonials">{t('home.homeTestimonials.button')}</Button>
        </Box>
      </Container>
    </Box>
  );
};