import React, { FC, useState} from 'react';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { Dialog, useMediaQuery, useTheme } from '@mui/material';
import { Box, Button, CircularProgress, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from "yup";
import emailjs from '@emailjs/browser';

interface IProps {
  openBookModal: boolean;
  handleOpenBookModal: () => void;
  handleCloseBookModal: () => void;
}

const mailConfig = {
  serviceId: 'service_37zd9ed',
  templateId: 'template_ysmjcg2',
  publicKey: '6XV40BJHR9WR8krGP'
}

export const BookModalComponent: FC<IProps> = ({ openBookModal, handleCloseBookModal }) => {
  const theme = useTheme();
  const lessLg = useMediaQuery(theme.breakpoints.down('md'));
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSent, setIsSent] = useState<boolean>(false);

  const validationSchema = () => {
    return Yup.object().shape({
      name: Yup
      .string()
      .required('This field is required!'),
      lastname: Yup
      .string()
      .required('This field is required!'),
      email: Yup
      .string()
      .required('This field is required!'),
      contactNumber: Yup
      .number()
      .required('This field is required!'),
      message: Yup
      .string()
      .required('This field is required!'),
    });
  }

  const formik = useFormik({
    initialValues: {
      name: '',
      lastname: '',
      email: '',
      contactNumber: '',
      message: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values: any) => {
    },
  });

  const handleSendData = () => {
    setIsLoading(true);
    const values = {
      ...formik.values
    }
    emailjs.send(mailConfig.serviceId, mailConfig.templateId, values, mailConfig.publicKey)
      .then((result) => {
          setIsLoading(false);
          setIsSent(true);
      }, (error) => {
          setIsLoading(false);
          console.log(error.text);
      });
  }
  
  return (
    <Dialog
      fullScreen={lessLg}
      maxWidth='lg'
      open={openBookModal}
      onClose={handleCloseBookModal}
    >
      <Box sx={{
        padding: { xs: '0 6px', md: '36px' }
      }}>
        <IconButton color='primary' sx={{ position: 'absolute', top: '34px', right: '28px' }} aria-label="close" onClick={handleCloseBookModal}>
          <CloseIcon />
        </IconButton>
        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{
          padding: { xs: '36px 12px 12px 12px', md: '0' }
        }}>
          {isSent ? 'Hvala Vam na prijavi' : 'Zakažite konsultacije' }
        </Typography>
        <Box sx={{ width: '100%' }}>
          {isSent ? (
            <Box sx={{ padding: { xs: '20px 12px 12px 12px', md: '20px 0 12px 0' } }}>
              <Typography>
                Kontakiraću Vas u najkraćem roku!
              </Typography>
              <Typography sx={{ mt: '10px'}}>
                Srdačno, Aleksandra
              </Typography>
            </Box>
          ) : (
            <React.Fragment>
              <Box sx={{ marginTop: '24px' }}>
                <form onSubmit={formik.handleSubmit}>
                  <TextField
                    sx={{ marginBottom: '24px' }}
                    fullWidth
                    id="name"
                    name="name"
                    label="Ime"
                    type="text"
                    required
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  />
                  <TextField
                    sx={{ marginBottom: '24px' }}
                    fullWidth
                    id="lastname"
                    name="lastname"
                    label="Prezime"
                    type="text"
                    required
                    value={formik.values.lastname}
                    onChange={formik.handleChange}
                    error={formik.touched.lastname && Boolean(formik.errors.lastname)}
                    helperText={formik.touched.lastname && formik.errors.lastname}
                  />
                  <TextField
                    sx={{ marginBottom: '24px' }}
                    fullWidth
                    id="email"
                    name="email"
                    label="Email"
                    type="email"
                    required
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                  <TextField
                    sx={{ marginBottom: '24px' }}
                    fullWidth
                    id="contactNumber"
                    name="contactNumber"
                    label="Broj telefona"
                    type="number"
                    required
                    value={formik.values.contactNumber}
                    onChange={formik.handleChange}
                    error={formik.touched.contactNumber && Boolean(formik.errors.contactNumber)}
                    helperText={formik.touched.contactNumber && formik.errors.contactNumber}
                  />
                  <TextField
                    sx={{ marginBottom: '24px' }}
                    fullWidth
                    id="message"
                    name="message"
                    label="Tema na kojoj želim da radim"
                    type="textarea"
                    required
                    value={formik.values.message}
                    onChange={formik.handleChange}
                    error={formik.touched.message && Boolean(formik.errors.message)}
                    helperText={formik.touched.message && formik.errors.message}
                  />
                </form>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row', padding: '16px', justifyContent: 'flex-end' }}>
                {isLoading ? (
                  <Box sx={{ display: 'flex' }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <Button
                    disabled={JSON.stringify(formik.initialValues) === JSON.stringify(formik.values) || !formik.isValid}
                    onClick={handleSendData}
                  >
                    Pošalji
                  </Button>
                )}
              </Box>
            </React.Fragment>
          )}
        </Box>
      </Box>
    </Dialog>
  );
}