import React from 'react';
import { Box } from '@mui/material';
import { Container } from '@mui/material';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Icon } from '@mui/material';

const textOne = [
  "Za sve koji žele da pronađu sebe",
  "Za sve koji žele da napuste osećaj žrtve i da budu kreatori svoje realnosti",
  "Za sve koji žele da ostvaruju svoje ciljeve",
  "Za sve koje žele da žele mir i ravnotežu",
  "Za sve koji žele da manifestuju ljubav i svoju srodnu dušu",
  "Za sve koji žele bolju komunikaciju i razumevanje u odnosima",
  "Za sve koji žele da unaprede samopouzdanje i veruju u sebe",
  "Za sve koji žele više volje, snage i motivisanosti"
];

const textTwo = [
  "Možeš da osvestiš gde sebe sabotiraš i kako to da prevaziđeš",
  "Možeš da pronađeš svoju svrhu i smisao",
  "Možeš da dobiješ snagu volje, entuzijazam i više životne energije",
  "Možeš da naučiš kako da sa sigurnošću iskazuješ sebe i svoje stavove",
  "Možeš da postaneš odlučniji, kreativniji i zadovoljniji sobom",
  "Možeš da naučiš kako da radiš stvari kroz lakoću bez odlaganja",
  "Možeš da naučiš kako da podržiš sebe u izazovnim situcaijama",
  "Možeš da naučiš kako da poštuješ, ceniš i vrednuješ sebe"
];

export const OneOnOne = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{
        width: '100%',
        position: 'relative'
      }}>
        <Box sx={{
          zIndex: '-1',
          backgroundColor: theme.palette.secondary.main,
          position: 'absolute',
          top: '0',
          left: '0',
          right: '0',
          bottom: '20%'
        }}></Box>
        <Container maxWidth="xl" sx={{
          padding: { xs: '40px', lg: '64px' },
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center'
        }}>
          <Typography mb='16px' variant='h3'>Individualne seanse</Typography>
          <Typography variant='subtitle2' color="text.primary" sx={{ maxWidth: '700px' }}>
            Kroz individualne seanse ćemo napraviti pozitivnu promenu na najdubljem nivou.
          </Typography>
          <Box sx={{
            margin: { xs: '24px auto', lg: '32px 0' },
            width: '80px',
            height: '6px',
            backgroundColor: 'rgba(0, 0, 0, 0.1)'
          }}></Box>
          <img src='assets/images/IMG_8722.jpg' alt='seanse' width='100%' height='auto' />
        </Container>
      </Box>
        <Container maxWidth="xl" sx={{
          padding: { xs: '0 40px 40px 40px', lg: '0 64px 64px 64px' },
          textAlign: 'center'
        }}>
          <Box sx={{
            textAlign: 'center',
            maxWidth: '800px',
            margin: '0 auto',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
            <Typography variant="h5" mb='16px'>Svaki problem sa kojim se trenutno suočavamo ima i svoj koren u podsvesti i u potisnutim osećajima koji se nalaze unutar našeg uma i tela.</Typography>
            <Typography variant="body1" mb='16px'>Ako vam se neki problemi stalno ponavljaju, ako se osećate kao da ne napredujete, znači da tu postoje neka negativna uverenja koja je potrebno izmeniti.</Typography>
            <Typography variant="body1">Na seansama se fokusiramo na ono kako se osećate u vezi vašeg problema, odnosno radimo na podsvesnim obrascima, na onome što ste nesvesno potisnuli. U procesu vas vodim da sami osvestite verovanja i osećaje koji vas sputavaju, te ih zajedno kroz isti proces menjamo i transformišemo u podržavajuće. Kada se ove blokade iscele i promene, osećamo se rasterećeno, entuzijastično i spremno da kreiramo život i navike po svojoj meri. Nakon seanse dobijate jasnoću, više volje, ohrabrenje i želju da nastavite tamo gde ste stali. Proces je poput meditacije u kom vas razgovorom različitim pitanjima vodim da razumete i iscelite sebe. </Typography>
            <Box sx={{
              margin: { xs: '24px auto', lg: '32px 0' },
              width: '80px',
              height: '6px',
              backgroundColor: theme.palette.secondary.main
            }}></Box>
          </Box>
         <Grid container spacing={0} columns={3}>
          <Grid item xs={3} lg={1}>
            <Typography variant='h4' sx={{ paddingBottom: '18px', textAlign: 'left',  width: { xs: '100%', lg: '80%' } }}>Kome su namenje?</Typography>
          </Grid>
          <Grid item xs={3} lg={2}>
            <Box sx={{
              paddingLeft: '12px',
              position: 'relative',
              textAlign: { xs: 'center', lg: 'left' }
            }}
            >
              {textOne.map((text, i) => (
                <Box key={i} sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: { xs: 'flex-start', lg: 'center' },
                  padding: '16px 0',
                  '&:not(:last-of-type)': {
                    borderBottom: '1px solid rgba(0, 0, 0, 0.1)'
                  }
                }}>
                  <Icon sx={{ fontSize: 18, color: theme.palette.primary.main, marginRight: '5px' }}>check</Icon>
                  <Typography variant='body1' sx={{ fontSize: '18px', lineHeight: '125%', textAlign: 'left' }}>{text}</Typography>
                </Box>
              ))}
            </Box>
          </Grid>
        </Grid>
        <Box sx={{
          margin: { xs: '24px auto', lg: '32px 0' },
          width: '100%',
          height: '10px',
          backgroundColor: theme.palette.secondary.main
        }}></Box>
        <Grid container spacing={0} columns={3}>
          <Grid item xs={3} lg={1}>
            <Typography variant='h4' sx={{ paddingBottom: '18px', textAlign: 'left' }}>Šta možeš da postigneš na seansama?</Typography>
          </Grid>
          <Grid item xs={3} lg={2}>
            <Box sx={{
              paddingLeft: '12px',
              position: 'relative',
              textAlign: { xs: 'center', lg: 'left' }
            }}
            >
              {textTwo.map((text, i) => (
                <Box key={i} sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: { xs: 'flex-start', lg: 'center' },
                  padding: '16px 0',
                  '&:not(:last-of-type)': {
                    borderBottom: '1px solid rgba(0, 0, 0, 0.1)'
                  }
                }}>
                  <Icon sx={{ fontSize: 18, color: theme.palette.primary.main, marginRight: '5px' }}>check</Icon>
                  <Typography variant='body1' sx={{ fontSize: '18px', lineHeight: '125%', textAlign: 'left' }}>{text}</Typography>
                </Box>
              ))}
            </Box>
          </Grid>
        </Grid>
        </Container>
    </Box>
  );
};