import React, { FC } from 'react';
import { Box, Button, Container, Icon, IconButton, Typography, useTheme, } from '@mui/material';
import { useTranslation } from 'react-i18next';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import { HomeLogoComponent } from '../../components';

interface IProps {
  handleOpenBookModal: () => void;
}

export const FooterComponent: FC<IProps> = ({ handleOpenBookModal }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Box sx={{
      width: '100%'
    }}>
      <img src='assets/images/footer.png' width='100%' height='auto' />
      <Box sx={{
        width: '100%',
        backgroundColor: theme.palette.secondary.main,
        marginTop: '-5px'
      }}>
        <Container maxWidth="xl" sx={{
          padding: { xs: '40px 40px 0 40px', lg: '64px 64px 0 64px' }
        }}>
          <Box sx={{ textAlign: 'center' }}>
            <img src='assets/images/aleksandra-cta.png' width='240px' height='auto' />
            <Typography mt='32px' mb='16px' variant="h5" color="primary">{t('home.avatarBanner.title')}</Typography>
            <Typography mb='32px' variant='body1'>{t('home.avatarBanner.description')}</Typography>
            <Button onClick={handleOpenBookModal} variant='contained'>{t('home.avatarBanner.button')}</Button>
          </Box>
          <Box sx={{
            marginTop: { xs: '60px', lg: '78px' },
            borderTop: '2px solid rgba(0, 0, 0, 0.1)',
            padding: { xs: '32px 0', lg: '40px 0' },
            display: 'flex',
            flexDirection: { xs: 'column', lg: 'row' },
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
            <HomeLogoComponent />
            <Typography variant="body2" color="text.primary" sx={{ textAlign: 'center', margin: { xs: '24px', lg: '0' } }}>
              {t('footer.rights')}
            </Typography>
            <Box sx={{ width: '230px', textAlign: { xs: 'center', lg: 'right' } }}>
              <IconButton href='https://www.instagram.com/thetaphilosophy/' target='_blank' aria-label="instagram">
                <InstagramIcon color='primary' />
              </IconButton>
              <IconButton href='https://www.facebook.com/profile.php?id=100086337486707' target='_blank' aria-label="facebook">
                <FacebookIcon color='primary' />
              </IconButton>
              <IconButton href='https://www.tiktok.com/@healingphilosophy' target='_blank' aria-label="instagram">
                <Icon color='primary'>tiktok</Icon>
              </IconButton>
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};