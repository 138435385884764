import React from 'react';
import ReactPlayer from 'react-player'
import { Box, Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const About = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const grSmall = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Box sx={{
      width: '100%',
    }}>
      <Box sx={{
        width: '100%',
        backgroundImage: { xs: 'url("assets/images/about-background.png")', lg: 'url("assets/images/about-background-x2.png")'  },
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
      }}>
        <Container maxWidth="xl" sx={{
          padding: { xs: '40px', lg: '64px' },
          textAlign: 'center'
        }}>
          <Typography mb='24px' variant='h3'>Par reči od Aleksandre</Typography>
          <Box sx={{ padding: '0 12px', display: 'flex', justifyContent: 'center' }}>
            <ReactPlayer
              controls={true} url='https://youtu.be/g23V-iS9tDU'
              width={ grSmall ? '640px' : '440px' }
              height={ grSmall ? '360px' : '260px' }
            />
          </Box>
        </Container>
      </Box>
      <Container maxWidth="xl" sx={{
        padding: { xs: '40px', lg: '64px' },
      }}>
        <Grid container spacing={0} columns={2}>
          <Grid item xs={2} lg={1}>
            <img src='assets/images/about.jpg' alt='about me' width='100%' height='auto' />
          </Grid>
          <Grid item xs={2} lg={1}>
            <Box sx={{
              padding: '36px',
              position: 'relative',
              textAlign: { xs: 'center', lg: 'left' }
            }}
            >
              <Typography mb='8px' variant='h5'>Sertifikovani sam praktičar ThetaHealing tehnike i tehnike Integracije, kroz koje uspešno možemo da otkrijemo uzroke i korene svojih problema.</Typography>
              <Box sx={{
                margin: { xs: '24px auto', lg: '24px 0' },
                width: '80px',
                height: '6px',
                backgroundColor: theme.palette.secondary.main
              }}></Box>
              <Typography variant="body1" mb='16px'>Moj cilj je da vam pomognem da spoznate sebe, da osvestite svoj potencijal kao i da budete kreatori zadovoljnog i ispunjenog zivota.</Typography>
              <Typography variant="body1" mb='16px'>I sama sam konstantno posvećena svom ličnom razvoju i mnogo me raduje što imam priliku da i vama budem podrška na putu ka otvarenju ličnih ciljeva.</Typography>
            </Box>
          </Grid>
        </Grid>
        <Box sx={{ textAlign: 'center', maxWidth: '800px', margin: '64px auto 0 auto' }}>
          <Typography variant="body1" mb='16px'>Iako sam po struci diplomirani ekonomista, poslovi u struci me nikad nisu zanimali. Uvek sam bila orijentisana prema kreativnim stvarima, gde bih mogla da izrazim sebe i ono kako se osećam. Ono što me je uvek pratilo i kada sam postigla neki uspeh u sferi svog interesovanja je bio konstantan osećaj nezadovoljstva i osećaj da to što radim nije moj pun potencijal. Duboko sam osećala da moj život ima neki veći smisao i svrhu. Ovi osećaji su me pratili sve dok se nisam našla u situaciji koja mi je izazvala jake nemire, strahove i veliku anksioznost. Sve ovo me je činilo da se osećam nemoćno, naizgled bezizlano i preplašeno. Tragajući za načinom da sebi pomognem i da prevaziđem probleme koji su me zadesili slučajno otkrivam ThetaHealing tehniku, i rešavam svoje strahove i anksioznost kroz istu. Ova tehnika mi je pomogla da se vratim sebi, da spoznam ko sam i šta daje smisao mom životu. Kroz rad na sebi i isceljivanje svog uma i tela otkrivam koliko svako od nas poseduje sposobnost da promeni svoj zivot, te shvatam da želim da prenosim svoje iskustvo dalje kako bi pomogla ljudima da prevaziđu sve ono što ih trenutno muči i sputava.</Typography>
          <Typography variant="body1" mb='16px'>Završila sam edukacije za ThetaHealing kroz brojne seminare, posle kojih postepeno krećem da radim sa prijateljima a onda i sa klijentima. Kroz praksu i dalje učim, razvijam sebe i prenosim dalje svoje iskustvo isceljenja kako bi što više ljudi osvestilo da za sve postoji rešenje. Kasnije se edukujem i za Integration tehnique, tehniku koja mi je pomogla da jos dublje udjem u srž i problematiku životnih poteškoća i podsvesnih obrazaca. Ova tehnika me je ostavila bez teksta jer kroz nju sada još brže i efikasnije možemo da se oslobodimo negativnosti i iscelimo naš um i naše telo.</Typography>
          <Typography variant="h5">Sada iz svog rada i prakse mogu da kažem da sam pomogla mnogima da prevaziđu razne strahove, traume, problematične odnose, manjak samopouzdanja, anksioznost i slično.</Typography>
        </Box>
      </Container>
    </Box>
  );
};