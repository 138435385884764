import { createTheme } from "@mui/material";
import "@fontsource/marcellus";
import "@fontsource/work-sans";
import Button from '@mui/material/Button';

export const theme = createTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#7B593C',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: '#F1DFDE',
      light: 'rgba(241, 223, 222, 0.5)'
    },
    text: {
      primary: '#666666',
      secondary: '#252525'
    },
    common: {
      black: '#000000',
      white: '#ffffff'
    },
    info: {
      main: '#666666'
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
  typography: {
    fontFamily: 'Work Sans',
    h1: {
      fontFamily: 'Marcellus'
    },
    h2: {
      fontFamily: 'Marcellus',
      '@media (max-width:1200px)': {
        fontSize: '2.5rem',
      },
    },
    h3: {
      fontFamily: 'Marcellus',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '36px',
      lineHeight: '45.14px',
      color: '#252525',
      '@media (min-width:1200px)': {
        fontSize: '48px',
        lineHeight: '60px',
      }
    },
    h4: {
      fontFamily: 'Marcellus',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '32px',
      lineHeight: '40.14px',
      color: '#252525',
      '@media (min-width:1200px)': {
        fontSize: '48px',
        lineHeight: '60px',
      }
    },
    h5: {
      fontFamily: 'Marcellus',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '28px',
      lineHeight: '35px',
      '@media (min-width:1200px)': {
        fontSize: '32px',
        lineHeight: '40px',
      }
    },
    h6: {
      fontFamily: 'Marcellus'
    },
    subtitle1: {
      fontFamily: 'Marcellus',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '24px',
      lineHeight: '30px',
      '@media (min-width:1200px)': {
        fontSize: '28px',
        lineHeight: '35px',
      }
    },
    subtitle2: {
      fontSize: '16px',
      lineHeight: '22.4px',
      '@media (min-width:1200px)': {
        fontSize: '20px',
        lineHeight: '23px'
      }
    },
    body1: {
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '16px',
      lineHeight: '140%',
      color: '#666666'
    },
    body2: {
      fontSize: '14px',
      lineHeight: '140%'
    },
    caption: {
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '14px',
      textTransform: 'uppercase',
      letterSpacing: '0.1em'
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderWidth: '2px',
          borderRadius: '0',
          fontStyle: 'normal',
          fontWeight: '500',
          fontSize: '16px',
          letterSpacing: '0.08em',
          lineHeight: '19px',
          padding: '16px 24px',
          ':hover': {
            borderWidth: '2px',  
          },
          '&.MuiButton-containedInfo': {
            backgroundColor: '#ffffff',
            color: '#7B593C',
            ':hover': {
              backgroundColor: 'rgba(249, 248, 247)'
            }
          }
        }
      }
    }
  }
});