import React, { useEffect, useMemo, useState } from 'react';
import { ThemeProvider } from '@emotion/react';
import { Route, Routes } from 'react-router-dom';
import { theme } from './assets/theme';
import { BookModalComponent, FooterComponent, NavigationComponent } from './components';
import { About, Blog, Home, Login, OneOnOne, Profile, Register, Testimonials } from './pages';
import './i18n/config';
import AuthVerify from './common/auth-verify';
import { AuthService } from './services';
import { IUser } from './models';

interface IAppState {
  showModeratorBoard: boolean,
  showAdminBoard: boolean,
  currentUser: IUser | undefined
}

export const App = () => {
  const authService = useMemo(() => new AuthService(), []);
  const [state, setState] = useState<IAppState>({
    showModeratorBoard: false,
    showAdminBoard: false,
    currentUser: undefined
  });

  const [openBookModal, setOpenBookModal] = React.useState(false);

  const handleOpenBookModal = () => setOpenBookModal(true);
  const handleCloseBookModal = () => setOpenBookModal(false);

  useEffect(() => {
    const user = authService.getCurrentUser();
    if (user) {
      setState({
        currentUser: user,
        showModeratorBoard: user.roles.includes("ROLE_MODERATOR"),
        showAdminBoard: user.roles.includes("ROLE_ADMIN"),
      });
    }
  }, [authService]);

  const logOut = () => {
    authService.logout();
    setState({
      showModeratorBoard: false,
      showAdminBoard: false,
      currentUser: undefined,
    });
  }
  
  return (
      <ThemeProvider theme={theme}>
        <NavigationComponent currentUser={state.currentUser} logOut={logOut} handleOpenBookModal={handleOpenBookModal} />
        <Routes>
          <Route path="/" element={ <Home handleOpenBookModal={handleOpenBookModal} />} />
          <Route path="/about" element={ <About />} />
          <Route path="/programs" element={ <OneOnOne />} />
          <Route path="/testimonials" element={ <Testimonials /> } />
          <Route path="/blog" element={ <Blog /> } />
          <Route path="/login" element={ <Login /> } />
          <Route path="/register" element={ <Register /> } />
          <Route path="/profile" element={ <Profile /> } />
        </Routes>
        <AuthVerify logOut={logOut}/>
        <BookModalComponent
          openBookModal={openBookModal}
          handleOpenBookModal={handleOpenBookModal}
          handleCloseBookModal={handleCloseBookModal}
        />
        <FooterComponent handleOpenBookModal={handleOpenBookModal} />
      </ThemeProvider>
  );
};