import React from 'react';
import { Box, Card, CardContent, Container, Grid, ImageList, ImageListItem, Typography, useMediaQuery, useTheme } from '@mui/material';
import { TestimonialCardsComponent } from '../../components';

export const Testimonials = () => {
  const theme = useTheme();
  const lessLg = useMediaQuery(theme.breakpoints.down('lg'));

  const IMAGEURLS = [
    'assets/images/comment1.jpg',
    'assets/images/comment4.jpeg',
    'assets/images/comment2.jpeg',
    'assets/images/comment6.jpeg',
    'assets/images/comment3.jpg',
    'assets/images/comment7.jpg',
    'assets/images/comment8.jpg',
    'assets/images/comment9.jpg',
    'assets/images/comment10.jpg',
    'assets/images/comment11.jpeg'
  ]

  return (
    <Box sx={{
      width: '100%',
      background: 'linear-gradient(180deg, rgba(241, 223, 222, 0.5) 0%, rgba(241, 223, 222, 0) 22.59%);'
    }}>
      <Container maxWidth="xl" sx={{
        padding: { xs: '40px', lg: '64px' },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center'
        }}>
          <Typography mb='16px' variant='h3'>Iskustva klijenata</Typography>
          <Typography variant='subtitle2' color="text.primary" sx={{ maxWidth: '700px' }}>
            Moj cilj je da vam pomognem da spoznate sebe, da osvestite svoj potencijal kao i da budete kreatori zadovoljnog i ispunjenog života.
          </Typography>
          <Box sx={{
            margin: { xs: '24px auto', lg: '32px 0' },
            width: '80px',
            height: '6px',
            backgroundColor: theme.palette.secondary.main
          }}></Box>
        </Box>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}>
          {lessLg ? (
            <Grid container spacing={2} columns={12}>
              {IMAGEURLS.map(item => (
                <Grid item xs={12} key={item} sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  margin: '0 24px'
                }}>
                  <Card sx={{
                    padding: '0',
                    borderRadius: '24px',
                    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'
                  }}>
                    <CardContent sx={{
                      padding: '0',
                      paddingBottom: '0 !important' 
                    }}>
                      <img src={item} alt={item} width="100%" height="auto" />
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          ) : (
            <ImageList variant="masonry" cols={3} gap={8}>
              {IMAGEURLS.map((item) => (
                <ImageListItem key={item} style={{ padding: '16px' }}>
                  <Card sx={{
                    padding: '0',
                    borderRadius: '24px',
                    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'
                  }}>
                    <CardContent sx={{
                      padding: '0',
                      paddingBottom: '0 !important' 
                    }}>
                      <img src={item} alt={item} width="100%" height="auto" />
                    </CardContent>
                  </Card>
                </ImageListItem>
              ))}
            </ImageList>
          )}
        </Box>
        <Box sx={{
          margin: '32px 0',
          width: '80px',
          height: '6px',
          backgroundColor: theme.palette.secondary.main
        }}></Box>
        <TestimonialCardsComponent />
      </Container>
    </Box>
  );
};