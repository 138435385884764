import React, { FC } from 'react';
import { Box, Button, Container, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface IProps {
  handleOpenBookModal: () => void;
}

export const HeaderComponent: FC<IProps> = ({ handleOpenBookModal }) => {
  const theme = useTheme();
  const lessLg = useMediaQuery(theme.breakpoints.down('lg'));
  const { t } = useTranslation();

  return (
    <Box sx={{
      width: '100%',
      backgroundColor: theme.palette.secondary.main,
      position: 'relative'
    }}>
      <Container maxWidth="xl" sx={{
        paddingTop: { xs: '40px', lg: '64px' },
        textAlign: 'center'
      }}>
        <Typography variant='h3' sx={{ marginBottom: { xs: '14px', lg: '16px' } }}>{t('home.header.title')}</Typography>
        <Typography variant='subtitle2'>{t('home.header.description')}</Typography>
        <Button onClick={handleOpenBookModal} variant='outlined' sx={{ marginTop: { xs: '24px', lg: '32px' } }}>{t('home.header.button')}</Button>
        <img src={lessLg ? 'assets/images/header.png' : 'assets/images/header-x2.png'} alt='header' width='100%' height='auto' style={{ position: 'relative', zIndex: '1' }} />
      </Container>
      <img src='assets/images/ilustracija-background.png' alt='header-background' width='100%' height='auto' style={{
        position: 'absolute',
        right: '0',
        left: '0',
        bottom: '0'
      }} />
    </Box>
  );
};