import React, { FC } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const HomeLogoComponent: FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: { xs: 'center', lg: 'left' } }}>
      <Typography
        variant="subtitle1"
        component="a"
        href="/"
        color="primary"
        sx={{
          textDecoration: 'none',
        }}
      >
        Aleksandra Škrbić
      </Typography>
      <Typography
        color="text.primary"
        variant="caption"
        component="a"
        href="/"
        sx={{
          textTransform: 'uppercase',
          textDecoration: 'none'
        }}
      >
        Duhovni učitelj i iscelitelj
      </Typography>
    </Box>
  );
};