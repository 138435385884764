import { Box, Button, Card, CardContent, CardHeader, Typography } from '@mui/material';
import React, { FC } from 'react';

interface IProps {
  item: any;
}

export const BlogCardComponent: FC<IProps> = ({ item }) => {

  return (
    <Card sx={{
      padding: '12px'
    }}>
      <CardHeader
        sx={{ paddingBottom: 0 }}
        title={
          <img
            width='100%'
            height='auto'
            src={`${item.img}?w=248&fit=crop&auto=format`}
            srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
            alt={item.title}
            loading="lazy"
          />
        }
      />
      <CardContent>
        <Typography mb='12px' variant='h4' color="primary">{item.title}</Typography>
        <Box sx={{ maxHeight: '200px' }}>
          <Typography sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'  }}>{item.content}</Typography>
          <Button sx={{ margin: '12px auto 0 auto' }} variant='contained'>Show More</Button>
        </Box>
      </CardContent>
    </Card>
  );
}