import React, { FC } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { ICardTestimonial } from '../../models';

interface IProps {
  item: ICardTestimonial;
}

export const TestimonialCardComponent: FC<IProps> = ({ item }) => {
  return (
    <Grid item xs={12} lg={6} key={item.name}>
      <Box sx={{ position: 'relative', paddingTop: '32px' }}>
        <Box sx={{ position: 'absolute', top:'0', left: { xs: '24px', lg: '40px' }, zIndex: '1' }}>
          <img src='assets/images/quote.png' alt='header' width='58px' height='auto' />
        </Box>
        <Box sx={{
          backgroundColor: '#FEF5EE',
          padding: { xs: '32px 24px', lg: '40px' },
          borderRadius: '24px',
          position: 'relative'
        }}>
          <Typography variant="body1">
            { item.comment }
          </Typography>
          <Typography variant="h5" color="primary" mt="16px">
            { item.name }
          </Typography>
        </Box>
      </Box>
    </Grid>
  );
}