import React, { FC } from 'react';
import { Box, Container, Grid, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ICard } from '../../models';

export const HelpCardsComponent: FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const CARDS: ICard[] = [
    {
      icon: "assets/images/imas-vise-samopouzdanja.png",
      title: t('home.helpCards.cards.expand.title'),
      description: t('home.helpCards.cards.expand.description')
    },
    {
      icon: "assets/images/napredujes-u-karijeri.png",
      title: t('home.helpCards.cards.work.title'),
      description: t('home.helpCards.cards.work.description')
    },
    {
      icon: "assets/images/unapredis-partnerske-odnese.png",
      title: t('home.helpCards.cards.favorite.title'),
      description: t('home.helpCards.cards.favorite.description')
    },
    {
      icon: "assets/images/poboljsas-komunikaciju-sa-drugima.png",
      title: t('home.helpCards.cards.chat.title'),
      description: t('home.helpCards.cards.chat.description')
    },
    {
      icon: "assets/images/promenis-lose-navike.png",
      title: t('home.helpCards.cards.sync.title'),
      description: t('home.helpCards.cards.sync.description')
    },
    {
      icon: "assets/images/unapredis-svoje-zdralvje.png",
      title: t('home.helpCards.cards.health_and_safety.title'),
      description: t('home.helpCards.cards.health_and_safety.description')
    }
  ]

  return (
    <Box sx={{
      width: '100%',
      backgroundColor: theme.palette.secondary.light
    }}>
      <Container maxWidth="xl" sx={{
        padding: { xs: '40px', lg: '64px' },
      }}>
        <Typography variant='h3' sx={{ textAlign: 'center', marginBottom: { xs: '32px', lg: '64px' } }}>
          { t('home.helpCards.title') }
        </Typography>
        <Grid container columns={12} sx={{ overflow: 'hidden' }}>
          {CARDS.map((card: ICard) => (
            <Grid item xs={12} lg={4} key={card.icon} sx={{
              padding: { xs: '16px', lg: '40px' },
              outline: { lg: '1.5px dashed rgba(0, 0, 0, 0.1)' },
              ':nth-of-type(-n + 3)': {
                paddingTop: { lg: '0'}
              },
              ':nth-of-type(n + 4)': {
                paddingBottom: { lg: '0' }
              },
              ':nth-of-type(3n)': {
                paddingRight: { lg: '0' }
              },
              ':nth-of-type(3n - 2)': {
                paddingLeft: { lg: '0' }
              },
            }}>
              <Box sx={{
                textAlign: { xs: 'center', lg: 'left' }
              }}>
                <img src={card.icon} alt='about me' width='80px' height='80px' />
                <Typography variant="h5" color="primary" sx={{ margin: '24px 0 16px 0' }}>
                  { card.title }
                </Typography>
                <Typography variant="body1" color="text.primary">
                  { card.description }
                </Typography>
              </Box>  
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};