import React, { FC, useMemo, useState } from 'react';
import { Alert, Box, Button, Card, CardContent, CardHeader, TextField, Typography} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFormik } from "formik";
import * as Yup from "yup";

import { AuthService } from "../../services";

interface IRegisterState {
  username: string,
  email: string,
  password: string,
  successful: boolean,
  message: string
}

export const Register: FC = () => {
  const { t } = useTranslation();
  const authService = useMemo(() => new AuthService(), []);
  const [state, setState] = useState<IRegisterState>({
    username: "",
    email: "",
    password: "",
    successful: false,
    message: ""
  });

  const validationSchema = () => {
    return Yup.object().shape({
      username: Yup.string()
        .test(
          "len",
          "The username must be between 3 and 20 characters.",
          (val: any) =>
            val &&
            val.toString().length >= 3 &&
            val.toString().length <= 20
        )
        .required("This field is required!"),
      email: Yup.string()
        .email("This is not a valid email.")
        .required("This field is required!"),
      password: Yup.string()
        .test(
          "len",
          "The password must be between 6 and 40 characters.",
          (val: any) =>
            val &&
            val.toString().length >= 6 &&
            val.toString().length <= 40
        )
        .required("This field is required!"),
    });
  }

  const formik = useFormik({
    initialValues: {
      username: '',
      email: "",
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleRegister(values)
    },
  });

  const handleRegister = (formValue: { username: string; email: string; password: string }) => {
    const { username, email, password } = formValue;

    setState({
      ...state,
      message: "",
      successful: false
    });

    authService.register(
      username,
      email,
      password
    ).then(
      response => {
        setState({
          ...state,
          message: response.data.message,
          successful: true
        });
      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setState({
          ...state,
          successful: false,
          message: resMessage
        });
      }
    );
  }

  return (
    <Box sx={{
      width: '100%',
      padding: '48px 0',
      backgroundColor: 'rgba(247,247,247,0.5)'
    }}>
      <Box sx={{
        width: '100%',
        maxWidth: '600px',
        margin: '0 auto'
      }}>
        <Card sx={{
          padding: '12px'
        }}>
          <CardHeader
            sx={{ paddingBottom: 0 }}
            title={
              <Typography mb='12px' variant='h4' color="primary">Register</Typography>
            }
          />
          <CardContent>
            {state.message && <Alert sx={{ marginBottom: '24px' }} severity={ state.successful ? 'success' : 'error'}>{state.message}</Alert>}
            {!state.successful && (
              <form onSubmit={formik.handleSubmit}>
                <TextField
                  sx={{ marginBottom: '24px' }}
                  fullWidth
                  id="username"
                  name="username"
                  label="Username"
                  value={formik.values.username}
                  onChange={formik.handleChange}
                  error={formik.touched.username && Boolean(formik.errors.username)}
                  helperText={formik.touched.username && formik.errors.username}
                />
                <TextField
                  sx={{ marginBottom: '24px' }}
                  fullWidth
                  id="email"
                  name="email"
                  label="Email"
                  type="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
                <TextField
                  sx={{ marginBottom: '24px' }}
                  fullWidth
                  id="password"
                  name="password"
                  label="Password"
                  type="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={formik.touched.password && Boolean(formik.errors.password)}
                  helperText={formik.touched.password && formik.errors.password}
                />
                <Button color="primary" variant="contained" type="submit">
                  Submit
                </Button>
              </form>
            )}
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};