import React, { FC } from 'react';
import { Grid } from '@mui/material';
import { ICardTestimonial } from '../../models';
import { useTranslation } from 'react-i18next';
import { TestimonialCardComponent } from '../TestimonialCard/TestimonialCard';

export const TestimonialCardsComponent: FC = () => {
  const { t } = useTranslation();

  const CARDS: ICardTestimonial[] = [
    {
      name: t('home.homeTestimonials.cards.comment1.name'),
      comment: t('home.homeTestimonials.cards.comment1.comment')
    },
    {
      name: t('home.homeTestimonials.cards.comment2.name'),
      comment: t('home.homeTestimonials.cards.comment2.comment')
    },
    {
      name: t('home.homeTestimonials.cards.comment3.name'),
      comment: t('home.homeTestimonials.cards.comment3.comment')
    },
    {
      name: t('home.homeTestimonials.cards.comment4.name'),
      comment: t('home.homeTestimonials.cards.comment4.comment')
    },
    {
      name: t('home.homeTestimonials.cards.comment5.name'),
      comment: t('home.homeTestimonials.cards.comment5.comment')
    },
    {
      name: t('home.homeTestimonials.cards.comment6.name'),
      comment: t('home.homeTestimonials.cards.comment6.comment')
    }
  ]

  return (
    <Grid container spacing={4} columns={12}>
      {CARDS.map((card: ICardTestimonial) => (
        <TestimonialCardComponent item={card} key={card.name} />
      ))}
    </Grid>
  );
}