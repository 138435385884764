import React, { useState, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { Dialog, useTheme } from '@mui/material';
import { IUser } from '../../models';
import CloseIcon from '@mui/icons-material/Close';
import { HomeLogoComponent } from '../../components';

interface IProps {
  currentUser: IUser | undefined;
  logOut: () => void;
  handleOpenBookModal: () => void;
}

export const NavigationComponent: FC<IProps> = ({ currentUser, logOut, handleOpenBookModal }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const theme = useTheme();
  const [path, setPath] = useState(location.pathname);
  const [open, setOpen] = React.useState(false);

  const PAGES = [
    {
      label: t('navigation.about'),
      url: "/about"
    },
    {
      label: t('navigation.programs'),
      url: '/programs'
    },
    {
      label: t('navigation.testimonials'),
      url: '/testimonials'
    },
    // {
    //   label: t('navigation.blog'),
    //   url: '/blog'
    // }
  ];

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseNavMenu = (path?: string) => {
    if (path) {
      setPath(path)
      navigate(path)
      setOpen(false);
    }
  };


  const openBookModal = () => {
    setOpen(false);
    handleOpenBookModal();
  }

  const handleGoToProfilePage = () => {
    setPath('/profile')
    navigate('/profile')
  }

  const handleLogout = () => {
    logOut();
    navigate('/')
  }

  return (
    <AppBar color="inherit" position="sticky">
      <Container maxWidth="xl">
        <Toolbar disableGutters sx={{ margin: { xs: '24px 8px', lg: '28px 40px' }, minHeight: { lg: 'inherit' } }}>
          <HomeLogoComponent />
          <Box sx={{ justifyContent: 'flex-end', flexGrow: 1, display: { xs: 'flex', lg: 'none' } }}>
            <IconButton
              size="large"
              onClick={handleClickOpen}
              color="primary"
            >
              <MenuIcon color="primary" />
            </IconButton>
            <Dialog
              fullScreen
              open={open}
              onClose={handleClose}
            >
              <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: { xs: '30px 24px', sm: '34px 32px' }
              }}>
                <HomeLogoComponent />
                <IconButton
                  edge="start"
                  color="primary"
                  onClick={handleClose}
                  aria-label="close"
                  sx={{
                    width: '48px',
                    height: '48px'
                  }}
                >
                  <CloseIcon  />
                </IconButton>
              </Box>
              <Box sx={{ marginLeft: { xs: '8px', sm: '18px' }, borderBottom: '1px solid rgba(0, 0, 0, 0.1)' }}>
                {PAGES.map((page) => (
                  <MenuItem selected={page.url === path} key={page.url} onClick={() => handleCloseNavMenu(page.url)} sx={{
                    paddingTop: '20px',
                    paddingBottom: '20px',
                    borderTop: '1px solid rgba(0, 0, 0, 0.1)',
                    backgroundColor: page.url === path ? '#F1DFDE !important' : 'inherit',
                    ':hover': {
                      backgroundColor: theme.palette.secondary.main
                    }
                  }}>
                    <Typography textAlign="center">{page.label}</Typography>
                  </MenuItem>
                ))}
              </Box>
              <Box sx={{ marginTop: '24px' }}>
                <Button sx={{ marginLeft: { xs: '8px', sm: '18px' } }} onClick={openBookModal} variant='contained'>{t('navigation.button')}</Button>
              </Box>
            </Dialog>
          </Box>
          <Box sx={{ justifyContent:'center', gap: '10px', flexGrow: 1, display: { xs: 'none', lg: 'flex' } }}>
            {PAGES.map((page) => (
              <Box key={page.label}>
                <Button
                  color='info'
                  onClick={() => handleCloseNavMenu(page.url)}
                  sx={{
                    my: 2,
                    display: 'block',
                    margin: '0',
                    borderRadius: '24px',
                    padding: '8px 16px',
                    backgroundColor: page.url === path ? theme.palette.secondary.main : 'inherit',
                    ':hover': {
                      backgroundColor: theme.palette.secondary.main
                    }
                  }}
                >
                  {page.label}
                </Button>
              </Box>
            ))}
          </Box>
          <Button sx={{ display: { xs: 'none', lg: 'block' } }} onClick={handleOpenBookModal} variant='contained'>{t('navigation.button')}</Button>
          
          {/* {currentUser ? (
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open profile">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUserMenu}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUserMenu)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem onClick={handleGoToProfilePage}>
                  <Typography textAlign="center">Profile</Typography>
                </MenuItem>
                <MenuItem onClick={handleLogout}>
                  <Typography textAlign="center">Logout</Typography>
                </MenuItem>
              </Menu>
            </Box>
          ) : (
            <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end' }}>
              <Box sx={{ marginBottom: '16px' }}>
                <Button
                  sx={{ my: 2, color: 'white', display: 'block', margin: '16px 0 0 0' }}
                  onClick={() => handleCloseNavMenu('/login')}
                >
                  Login
                </Button>
                {path === '/login' && <Divider color="white" sx={{ display: 'block' }} />}
              </Box>
              <Box sx={{ marginBottom: '16px' }}>
                <Button
                  sx={{ my: 2, color: 'white', display: 'block', margin: '16px 0 0 0' }}
                  onClick={() => handleCloseNavMenu('/register')}
                >
                  Register
                </Button>
                {path === '/register' && <Divider color="white" sx={{ display: 'block' }} />}
              </Box>
            </Box>
          )} */}
        </Toolbar>
      </Container>
    </AppBar>
  );
}